export enum Permission {
    WRITE_CUSTOMER = 'customer:write',

    WRITE_USER = 'user:write',
    DELETE_USER = 'user:delete',

    WRITE_SUBSCRIPTION = 'subscription:write',
    DELETE_SUBSCRIPTION = 'subscription:delete',

    WRITE_INVOICE = 'invoice:write',
    DELETE_INVOICE = 'invoice:delete',

    WRITE_PAYMENT = 'payment:write',
    DELETE_PAYMENT = 'payment:delete',

    READ_BANK_ACCOUNT = 'bank-account:read',
    READ_BANK_ACCOUNT_OUTGOING = 'bank-account:outgoing:read',
    READ_BANK_ACCOUNT_BALANCE = 'bank-account:balance:read',

    WRITE_FINANCIAL_CATEGORY = 'financial-category:write',
    DELETE_FINANCIAL_CATEGORY = 'financial-category:delete',
}

export const PermissionDisplay: Record<Permission, string> = {
    [Permission.WRITE_CUSTOMER]: 'Crear/Actualizar/Borrar Clientes',
    [Permission.WRITE_USER]: 'Crear/Actualizar Usuarios',
    [Permission.DELETE_USER]: 'Borrar Usuarios',
    [Permission.WRITE_SUBSCRIPTION]: 'Crear/Actualizar Cobros Recurrentes',
    [Permission.DELETE_SUBSCRIPTION]: 'Borrar/Archivar Cobros Recurrentes',
    [Permission.WRITE_INVOICE]: 'Crear/Actualizar Cobros',
    [Permission.DELETE_INVOICE]: 'Borrar Cobros',
    [Permission.WRITE_PAYMENT]: 'Crear Pagos',
    [Permission.DELETE_PAYMENT]: 'Borrar Pagos',
    [Permission.READ_BANK_ACCOUNT]: 'Acceso a Conciliación',
    [Permission.READ_BANK_ACCOUNT_OUTGOING]: 'Acceso a Cargos en Conciliación',
    [Permission.READ_BANK_ACCOUNT_BALANCE]: 'Acceso a Saldos en Conciliación',
    [Permission.WRITE_FINANCIAL_CATEGORY]: 'Crear/Actualizar Categorías Financieras',
    [Permission.DELETE_FINANCIAL_CATEGORY]: 'Borrar Categorías Financieras',
}

export const AllPermissions = Object.values(Permission)
