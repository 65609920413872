import { FC, useContext, useEffect, useMemo } from 'react'
import cx from 'classnames'
import { useRouter } from 'next/router'
import { useReadLocalStorage } from 'usehooks-ts'
import { subDays } from 'date-fns'

import Link from 'next/link'
import styles from './style.module.scss'
import { Button } from 'ds/components'

import { hasPermission } from 'lib/utils/hasPermission'
import { Permission } from 'lib/models/permission'
import { changelog } from 'lib/constants/changelog'
import { dateStringToDate } from 'lib/utils/dateStringUtils'
import { SessionContext } from 'lib/hoc/withSession'

type Props = {
    isLoading?: boolean
    showMainNavOnMobile: boolean
    setShowMainNavOnMobile: (show: boolean) => void
}

export const LayoutNav: FC<Props> = ({
    isLoading,
    showMainNavOnMobile,
    setShowMainNavOnMobile,
}) => {
    const router = useRouter()
    const changelogID = useReadLocalStorage('changelog')
    const { session } = useContext(SessionContext)

    const hasNewFeature = useMemo(() => {
        const idNum = parseInt(changelogID as string)
        const date = subDays(new Date(), 7)
        const newChangelogs = changelog.filter((c) => {
            if (c.id <= idNum) return false
            const cDate = dateStringToDate(c.date)
            return cDate && cDate > date
        })
        return !!newChangelogs.length
    }, [changelogID])

    useEffect(() => {
        setShowMainNavOnMobile(false)
    }, [router.pathname, setShowMainNavOnMobile])

    const hasAccessToBankAccounts = hasPermission(session, Permission.READ_BANK_ACCOUNT)

    const renderButton = (
        path: string,
        icon: string,
        text: string,
        extraUrl?: string
    ) => (
        <Button
            fullWidth
            left
            href={`${path}${extraUrl || ''}`}
            icon={icon}
            variant={
                router.pathname.startsWith(path) || path === '/changelog'
                    ? 'primaryText'
                    : 'secondaryText'
            }
        >
            {text}
        </Button>
    )

    return (
        <div
            className={cx(styles.mainNav, 'container-fluid stacked-md', {
                [styles.open]: showMainNavOnMobile,
            })}
        >
            <div
                className={cx(styles.overlay, {
                    [styles.open]: showMainNavOnMobile,
                })}
                onClick={() => setShowMainNavOnMobile(false)}
            />
            <div className="stacked-lg">
                <Link href="/dashboard">
                    <span className={cx(styles.logo)}>
                        <div className={styles.logoImg} />
                    </span>
                </Link>
            </div>
            {session.account_id ? (
                <>
                    <div className="stacked-md">{session.company}</div>
                    {renderButton('/dashboard', 'speedometer2', 'Dashboard')}
                    {/* Cobranza */}
                    {renderButton('/invoices', 'cash-stack', 'Cobranza')}
                    {renderButton('/subscriptions', 'repeat', 'Cobros Recurrentes')}
                    {renderButton('/customers', 'person', 'Clientes')}
                    {/* Conciliación */}
                    {hasAccessToBankAccounts ? (
                        session.has_financial_account ? (
                            <>
                                {renderButton(
                                    '/bank-accounts',
                                    'bank',
                                    'Cuentas Bancarias'
                                )}
                                {renderButton(
                                    '/journal-entries',
                                    'journal-bookmark-fill',
                                    'Pólizas Contables'
                                )}
                            </>
                        ) : (
                            renderButton('/bank-accounts', 'check2-all', 'Conciliación')
                        )
                    ) : null}
                    {/* Reportes */}
                    {renderButton('/reports', 'graph-up', 'Reportes')}
                    {session.role === 'superadmin'
                        ? renderButton('/superadmin', 'activity', 'Superadmin', '/usage')
                        : null}
                    <div className={styles.bottom}>
                        {hasNewFeature
                            ? renderButton(
                                  '/changelog',
                                  'patch-exclamation-fill',
                                  'Nueva Funcionalidad'
                              )
                            : null}
                        {renderButton('/settings', 'gear', 'Configuración')}
                    </div>
                </>
            ) : !isLoading ? (
                <div className={cx('stacked-sm ease', styles.item)}>
                    <div>
                        {renderButton('/login', 'arrow-right', 'Entra a tu cuenta')}
                        {renderButton('/register', 'arrow-right', 'Regístrate')}
                    </div>
                </div>
            ) : null}
        </div>
    )
}
