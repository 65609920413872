import { orderBy } from 'lodash'
import { ReactNode } from 'react'
import * as icons from 'react-bootstrap-icons'

import { TextLink } from 'ds/components'

type ChangeLogItem = {
    id: number
    date: string
    title: string
    description: ReactNode
    icon: keyof typeof icons
}

const changelogItems: ChangeLogItem[] = [
    {
        id: 6,
        date: '2024-11-07',
        title: 'Auto-Facturación',
        icon: 'FileText',
        description: (
            <div className="row gy-3">
                <div className="col-12 col-xl-6">
                    <h4>Incrementa tu productividad</h4>
                    <div className="neutral-600">
                        Ahora puedes permitir que tus clientes se auto-facturen por medio
                        del portal. Quitando carga administrativa en tu empresa, y
                        mejorándo la productividad del tu negocio.
                    </div>
                    <h4 className="mt-3">Fácil de usar</h4>
                    <div className="neutral-600">
                        El proceso de crear una factura es muy fácil para tus clientes,
                        simplemente seleccionan el cobro, hacen click en &quot;Generar
                        factura&quot;, y completan el formulario para generarla.
                    </div>
                    <h4 className="mt-3">Restrincciones</h4>
                    <div className="neutral-600">
                        Aplican las siguientes restrincciones para auto-facturación:
                        <ol>
                            <li>El cobro debe estar totalmente pagados</li>
                            <li>El cobro fue pagado en una sóla exhibición</li>
                            <li>
                                La factura tiene que crearse dentro del mismo mes del pago
                            </li>
                            <li>Sólo se permite PUE</li>
                        </ol>
                    </div>
                    <h4 className="mt-3">¿Como utilizar?</h4>
                    <div className="neutral-600">
                        Permite a tus clientes auto-facturarse{' '}
                        <TextLink href="/settings/portal">aquí.</TextLink>
                    </div>
                </div>

                <div className="col-12 col-xl-6 d-flex-center">
                    <iframe
                        allow="fullscreen"
                        width="619"
                        height="400"
                        src="https://www.loom.com/embed/f4900f5a64c54ec5877caa129429b6ae?sid=f1edca44-1cdf-49bf-8b2a-708244a3192f"
                        style={{ border: 0 }}
                    />
                </div>
            </div>
        ),
    },
    {
        id: 5,
        date: '2024-10-14',
        title: 'Adjunta Documentos en Recordatorios',
        icon: 'FileText',
        description: (
            <div className="row gy-3">
                <div className="col-12 col-xl-6">
                    <h4>Mejora tu cobranza</h4>
                    <div className="neutral-600">
                        Ahora puedes adjuntar facturas en formato pdf y xml en tus
                        recordatorios de pago, así como notas de venta. Mejorándo tu
                        cobranza y productividad.
                    </div>
                    <h4 className="mt-3">Automatización</h4>
                    <div className="neutral-600">
                        Si decides automatizar el envio de recordatorios, los documentos
                        que eligas se adjuntarán en tus recordatorios automáticos.
                    </div>
                    <h4 className="mt-3">Recordatorios de pago manuales</h4>
                    <div className="neutral-600">
                        Los documentos que eligas adjuntar en tus recordatorios de pago
                        también se incluirían en los recordatorios de pago que envies
                        manuamente.
                    </div>
                    <h4 className="mt-3">¿Como utilizar?</h4>
                    <div className="neutral-600">
                        Configura los documentos que quierens enviar{' '}
                        <TextLink href="/settings/invoice-reminders">aquí.</TextLink>
                        <ol>
                            <li>Selecciona los documentos que quieres adjuntar</li>
                            <li>Selecciona el botón de &quot;Guardar&quot;</li>
                            <li>¡Listo!</li>
                        </ol>
                    </div>
                </div>

                <div className="col-12 col-xl-6 d-flex-center">
                    <iframe
                        allow="fullscreen"
                        width="619"
                        height="400"
                        src="https://www.loom.com/embed/b5d73e0fec7c4212a899ad737ac08987?sid=bca8e1dc-fd60-4ecd-ba27-144242890f08"
                        style={{ border: 0 }}
                    />
                </div>
            </div>
        ),
    },
    {
        id: 4,
        date: '2024-08-05',
        title: 'Nuevo Estado de CXC',
        icon: 'FileEarmarkSpreadsheet',
        description: (
            <div className="row gy-3">
                <div className="col-12 col-xl-6">
                    <h4>Da visibilidad al saldo pendiente de tu cliente</h4>
                    <div className="neutral-600">
                        El nuevo estado de cuentas por cobrar desglosa los cobros que
                        componen la cartera vencida, la cartera por vencer, y la cartera
                        total de tu cliente.
                    </div>
                    <h4 className="mt-3">Descarga y envía</h4>
                    <div className="neutral-600">
                        Ahora puedes enviarle a tu cliente este estado de cuenta
                        directamente desde Savio. También tienes la opción de descargarlo
                        a un archivo PDF.
                    </div>
                    <h4 className="mt-3">Filtra por con o sin CFDI</h4>
                    <div className="neutral-600">
                        El nuevo estado de CXC te permite filtrar los cobros para ver
                        todos los cobros, sólo los facturados, o sólo los que no tienen
                        factura.
                    </div>

                    <h4 className="mt-3">Ayúdanos a mejorar</h4>
                    <div className="neutral-600">
                        El nuevo estado de CXC fue diseñado para ayudar a tu negocio a
                        cobrar mejor. Permite a tu cliente saber los cobros que componen
                        su cartera vencida, por vencer, y total. Si tienes ideas de cómo
                        mejorar el estado de CXC, ¡háznoslo saber!
                    </div>
                </div>

                <div className="col-12 col-xl-6 d-flex-center">
                    <iframe
                        allow="fullscreen"
                        width="619"
                        height="400"
                        src="https://www.loom.com/embed/82bc952c15a1435a8f344ac331723fef?sid=6bb88190-f77e-446f-84f8-d233a18ccd3f"
                        style={{ border: 0 }}
                    />
                </div>
            </div>
        ),
    },
    {
        id: 3,
        date: '2024-07-11',
        title: 'Plantillas de Correos',
        icon: 'Envelope',
        description: (
            <div className="row gy-3">
                <div className="col-12 col-xl-6">
                    <h4>Ve un preview de tu plantilla de correo</h4>
                    <div className="neutral-600">
                        Al editar una plantilla de correo, puedes seleccionar &quot;Vista
                        Previa&quot; para visualizar cómo se verá el correo, y asegurarte
                        que el correo está bien antes de guardarlo.
                    </div>
                    <h4 className="mt-3">Variables en español</h4>
                    <div className="neutral-600">
                        Ahora puedes encontrar las variables disponibles de cada plantilla
                        en español y inglés.
                    </div>
                    <h4 className="mt-3">¿Como utilizar?</h4>
                    <div className="neutral-600">
                        Edita una plantilla de correo{' '}
                        <TextLink href="/settings/email-templates">aquí.</TextLink>
                        <ol>
                            <li>Personaliza el correo con las variables que deseés</li>
                            <li>Selecciona el botón de &quot;Vista Previa&quot;</li>
                            <li>Visualiza cómo se verá tu correo</li>
                        </ol>
                    </div>
                    <h4 className="mt-3">Vendrá mucho mas en el futuro</h4>
                    <div className="neutral-600">
                        Tenemos planeado agregar más funcionalidades al portal de
                        plantillas de correo. Si tienes alguna idea de cómo mejorar el
                        portal, ¡háznoslo saber!
                    </div>
                </div>

                <div className="col-12 col-xl-6 d-flex-center">
                    <iframe
                        allow="fullscreen"
                        width="619"
                        height="400"
                        src="https://www.loom.com/embed/0553eb6a4aba492188c43fa05f5aeff0?sid=0e596e35-9408-45bc-8d23-7fbdbd914f08"
                        style={{ border: 0 }}
                    />
                </div>
            </div>
        ),
    },
    {
        id: 2,
        date: '2024-05-28',
        title: 'Portal de Cliente',
        icon: 'PersonWorkspace',
        description: (
            <div className="row gy-3">
                <div className="col-12 col-xl-6">
                    <h4>Dar visibilidad de su cobranza a tu cliente</h4>
                    <div className="neutral-600">
                        El portal le permite a tu cliente ver todos sus cobros y facturas
                        en un lugar. Puede ver el estado de sus pagos y descargar sus
                        facturas.
                    </div>
                    <h4 className="mt-3">Editen sus datos fiscales</h4>
                    <div className="neutral-600">
                        Si tu cliente necesita cambiar su dirección o RFC, puede hacerlo
                        directo en el portal. Así no tienes que estar actualizando sus
                        datos manualmente.
                    </div>
                    <h4 className="mt-3">¿Como utilizar?</h4>
                    <div className="neutral-600">
                        Activa el portal{' '}
                        <TextLink href="/settings/portal">aquí.</TextLink> Una vez este
                        activado hay tres maneras de acceder al portal:
                        <ol>
                            <li>
                                Bajo el menu de un cliente pica &quot;Copiar link al
                                portal&quot;
                            </li>
                            <li>
                                En la pagina de cliente puedes copiar el el link al portal
                            </li>
                            <li>
                                En las plantillas de correo puedes agregar el link al
                                portal con{' '}
                                <pre className="inline">{'{{enlace_portal}}'}</pre>
                            </li>
                        </ol>
                    </div>
                    <h4 className="mt-3">Vendrá mucho mas en el futuro</h4>
                    <div className="neutral-600">
                        Tenemos planeado agregar más funcionalidades al portal de cliente.
                        Si tienes alguna idea de cómo mejorar el portal, ¡háznoslo saber!
                    </div>
                </div>

                <div className="col-12 col-xl-6 d-flex-center">
                    <iframe
                        allow="fullscreen"
                        width="619"
                        height="400"
                        src="https://www.loom.com/embed/4fc0211644ec452185f3c2f8f137e054?sid=4fef109c-7ee3-465f-96ad-c8a090b71f96"
                        style={{ border: 0 }}
                    />
                </div>
            </div>
        ),
    },
    {
        id: 1,
        date: '2024-05-21',
        title: 'Tags',
        icon: 'TagFill',
        description: (
            <div className="row gy-3">
                <div className="col-12 col-xl-6">
                    <h4>Encuentra tus cobros</h4>
                    <div className="neutral-600">
                        Si cada cobro tiene su propio dueño, puedes usar tags para marcar
                        a quién le pertenece cada cobro. Así puedes filtrar tus cobros por
                        tag y encontrarlos más rápido.
                    </div>
                    <h4 className="mt-3">Divide un estado de cuenta</h4>
                    <div className="neutral-600">
                        Si tu cliente tiene dos planes de pago puedes filtrar un estado de
                        cuenta por tag. Asi puedes mandar el estado por cada plan de pago
                        a tu cliente.
                    </div>
                    <h4 className="mt-3">Tag un cobro recurrente</h4>
                    <div className="neutral-600">
                        Cuando un cobro recurrente tiene un tag asignado, todos los cobros
                        que se crean tendrán el mismo tag.
                    </div>
                    <h4 className="mt-3">Y mucho mas...</h4>
                    <div className="neutral-600">
                        El sistema de tags fue creado para ser muy flexible. Puedes
                        usarlos en las maneras que mejor se adapten a tu negocio. Si
                        tienes otras ideas de cómo usar los tags, ¡háznoslo saber!
                    </div>
                </div>

                <div className="col-12 col-xl-6 d-flex-center">
                    <iframe
                        allow="fullscreen"
                        width="619"
                        height="400"
                        src="https://www.loom.com/embed/08d5ddca6a64422b9f6e090aab03c98e?sid=a1f19fe8-7122-49f4-bc64-8f0775286018"
                        style={{ border: 0 }}
                    />
                </div>
            </div>
        ),
        // 'Ahora tus clientes pueden ver sus facturas y pagos en un portal personalizado. Puedes activar el portal en la configuración de tu cuenta.',
    },
]

export const changelog = orderBy(changelogItems, 'id', 'desc')
