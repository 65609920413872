import { makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'
import { InvoiceReminderConfig } from 'lib/models/invoiceReminderConfig'

const url = '/invoice-reminder-configs'

const baseKey = 'invoice_reminder_configs'

export const invoiceReminderConfigQueryKeys = {
    invalidateAll: [baseKey],
    getInvoiceReminderConfig: [baseKey],
    getCustomerInvoiceReminderConfig: (customerId: number) => [baseKey, { customerId }],
}

export const invoiceReminderConfigService = {
    getInvoiceReminderConfig: async (customerId?: number) =>
        makeApiRequest<GetInvoiceReminderConfigData>({
            url: customerId ? `${url}/customer/${customerId}` : url,
        }),
    // Put handles both insert or update
    putInvoiceReminderConfig: async (body: PutInvoiceReminderConfig) =>
        makeApiRequest({ url, method: 'PUT', body }),
    putCustomerInvoiceReminderConfig: async (body: PutCustomerInvoiceReminderConfig) =>
        makeApiRequest({ url: `${url}/customer`, method: 'PUT', body }),
}

type GetInvoiceReminderConfigData = {
    invoice_reminder_config: InvoiceReminderConfig
}

type PutInvoiceReminderConfig = {
    invoice_reminder_config_id: number | null
    email_sequence: string
    attachments: string
}

type PutCustomerInvoiceReminderConfig = {
    customer_id: number
    disable_reminders: boolean
}
