import { makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'

export const accountService = {
    create: async (body) => makeApiRequest({ url: '/register', method: 'POST', body }),
    get: async () => makeApiRequest({ url: '/account' }),
    update: async (body) => makeApiRequest({ url: '/account', method: 'PUT', body }),
    updateTemplates: async (body) =>
        makeApiRequest({ url: '/account/templates', method: 'PUT', body }),
    sendTestEmails: async (body) =>
        makeApiRequest({ url: '/test/emails', method: 'POST', body }),
    superadminGetAll: async () => makeApiRequest({ url: '/superadmin/accounts' }),
    getAllSeries: async () => makeApiRequest({ url: '/account/series' }),
}
